<widget-header
    [name]="widgetName"
    (onRenamed)="renameWidget($event)">

  <widget-menu
    slot="menu"
    [resource]="resource">
  </widget-menu>
</widget-header>

<div class="generic-table--results-container">
  <table class="generic-table">
    <colgroup>
      <col opHighlightCol>
      <col opHighlightCol>
    </colgroup>
    <thead >
      <tr>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span >Название</span>
            </div>
          </div>
        </th>
        <th>
          <div class="generic-table--sort-header-outer">
            <div class="generic-table--sort-header">
              <span >Статус</span>
            </div>
          </div>
        </th>
        <th><div class="generic-table--empty-header"></div></th>
      </tr>
    </thead>
    <tbody>
      <tr class="" *ngFor="let item of projectList">
        <td class="subject">
          <a href="/projects/{{item.id}}" [textContent]="item.name"></a>
        </td>
        <td class="status">
          {{item._links?.status?.title}}
        </td>
      </tr>
    </tbody>
  </table>
</div>

