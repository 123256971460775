// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, first, map } from 'rxjs/operators';

import { I18nService } from 'core-app/core/i18n/i18n.service';
import { PathHelperService } from 'core-app/core/path-helper/path-helper.service';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { HalResourceEditingService } from 'core-app/shared/components/fields/edit/services/hal-resource-editing.service';
import { DisplayFieldService } from 'core-app/shared/components/fields/display/display-field.service';
import { DisplayField } from 'core-app/shared/components/fields/display/display-field.module';
import { QueryResource } from 'core-app/features/hal/resources/query-resource';
import { HookService } from 'core-app/features/plugins/hook-service';
import { WorkPackageChangeset } from 'core-app/features/work-packages/components/wp-edit/work-package-changeset';
import { randomString } from 'core-app/shared/helpers/random-string';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { UntilDestroyedMixin } from 'core-app/shared/helpers/angular/until-destroyed.mixin';
import { CurrentProjectService } from 'core-app/core/current-project/current-project.service';
import { SchemaCacheService } from 'core-app/core/schemas/schema-cache.service';
import { ProjectsResourceService } from 'core-app/core/state/projects/projects.service';
import { CurrentUserService } from 'core-app/core/current-user/current-user.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';
import { ProjectStoragesResourceService } from 'core-app/core/state/project-storages/project-storages.service';
import { IProjectStorage } from 'core-app/core/state/project-storages/project-storage.model';
import idFromLink from 'core-app/features/hal/helpers/id-from-link';
import isNewResource from 'core-app/features/hal/helpers/is-new-resource';
import { IsolatedQuerySpace } from '../../directives/query-space/isolated-query-space';
import { WorkPackagesListService } from '../wp-list/wp-list.service';
import { SchemaResource } from 'core-app/features/hal/resources/schema-resource';
import { allBlockField, kpBlockField, workBlockField, workCloseBlockField, workPauseBlockField } from './config';
import { IFieldSchema } from 'core-app/shared/components/fields/field.base';
import { EditFieldService } from 'core-app/shared/components/fields/edit/edit-field.service';
import { CombinedCustomDateEditFieldComponent } from 'core-app/shared/components/fields/edit/field-types/combined-custom-date-edit-field.component';
import { AvailableCustomDateSlugs } from 'core-app/shared/components/fields/field.service';

export interface FieldDescriptor {
  name: string;
  label: string;
  field?: DisplayField;
  fields?: DisplayField[];
  spanAll: boolean;
  multiple: boolean;
  options?: { [key: string]: any };
}

export interface CustomFieldDescriptor extends FieldDescriptor {
  name: string;
  label: string;
  field?: DisplayField;
  fields?: DisplayField[];
  spanAll: boolean;
  multiple: boolean;
}

export interface GroupDescriptor {
  name: string;
  id: string;
  members: FieldDescriptor[];
  query?: QueryResource;
  relationType?: string;
  isolated: boolean;
  type: string;
}

export interface ResourceContextChange {
  isNew: boolean;
  schema: string | null;
  project: string | null;
}

export const overflowingContainerAttribute = 'overflowingIdentifier';

type AvailableCustomDateSlugsType = typeof AvailableCustomDateSlugs;
type GroupKey = keyof AvailableCustomDateSlugsType;
type AttributeKey<K extends GroupKey> = keyof AvailableCustomDateSlugsType[K];

export type TCustomDatesAttributes = { key: any, label: any, schema: any };

export type TGroupAttributes<K extends GroupKey> = {
  [A in AttributeKey<K>]?: TCustomDatesAttributes;
};
export type TCustomDatesGroup = {
  [K in GroupKey]?: TGroupAttributes<K>;
};
@Component({
  templateUrl: './wp-single-view.component.html',
  selector: 'wp-single-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkPackageSingleViewComponent extends UntilDestroyedMixin implements OnInit {
  @Input() public workPackage: WorkPackageResource;

  /** Should we show the project field */
  @Input() public showProject = false;

  // Grouped fields returned from API
  public groupedFields: GroupDescriptor[] = [];
  // Project context as an indicator
  // when editing the work package in a different project
  public projectContext: {
    matches: boolean;
    id: string | null;
    href: string | null;
    field?: FieldDescriptor[];
  };

  public text = {
    attachments: {
      label: this.I18n.t('js.label_attachments'),
    },
    files: {
      label: this.I18n.t('js.work_packages.tabs.files'),
    },
    project: {
      required: this.I18n.t('js.project.required_outside_context'),
    },

    fields: {
      description: this.I18n.t('js.work_packages.properties.description'),
    },
    infoRow: {
      createdBy: this.I18n.t('js.label_created_by'),
      lastUpdatedOn: this.I18n.t('js.label_last_updated_on'),
    },
    integration: this.I18n.t('js.button_integration_plan'),
  };

  public isFileNeeded: boolean;
  public isNewResource: boolean;
  private wpResource: WorkPackageResource;
  public uiSelfRef: string;

  private typeName: string;
  private statusName: string;

  // Block fields context
  private close = 'Прекращен';
  private pause = 'Приостановлен';
  private backlog = 'Бэклог';

  private workClose1 = 'Завершена';
  private workClose2 = 'Прекращена';
  private workPause = 'Приостановлена';

  private kp = 'КП';
  private stage = 'Этап';
  private work = 'Работа';

  $element: JQuery;

  projectStorages = new BehaviorSubject<IProjectStorage[]>([]);

  @ViewChild('queryNameField', { static: true }) queryNameField: ElementRef;

  constructor(
    public readonly injector: Injector,
    private readonly I18n: I18nService,
    private readonly hook: HookService,
    private readonly $state: StateService,
    private readonly elementRef: ElementRef,
    private readonly querySpace: IsolatedQuerySpace,
    readonly wpListService: WorkPackagesListService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly PathHelper: PathHelperService,
    private readonly schemaCache: SchemaCacheService,
    private readonly currentProject: CurrentProjectService,
    private readonly halEditing: HalResourceEditingService,
    private readonly halResourceService: HalResourceService,
    private readonly currentUserService: CurrentUserService,
    private readonly displayFieldService: DisplayFieldService,
    private readonly projectsResourceService: ProjectsResourceService,
    private readonly projectStoragesService: ProjectStoragesResourceService,
    private readonly editFieldService: EditFieldService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.$element = jQuery(this.elementRef.nativeElement as HTMLElement);
    this.isNewResource = isNewResource(this.workPackage);
    this.uiSelfRef = this.$state.$current.name;

    if (!this.querySpace.query.value) {
      void this.wpListService.loadDefaultQuery();
    }
    const change = this.halEditing.changeFor<WorkPackageResource, WorkPackageChangeset>(this.workPackage);
    this.refresh(change);

    // Whenever the temporary resource changes in any way,
    // update the visible fields.
    this.halEditing
      .temporaryEditResource(this.workPackage)
      .values$()
      .pipe(
        this.untilDestroyed(),
        map((resource) => this.contextFrom(resource)),
        distinctUntilChanged<ResourceContextChange>((a, b) => _.isEqual(a, b)),
        map(() => this.halEditing.changeFor(this.workPackage))
      )
      .subscribe((changeset: WorkPackageChangeset) => this.refresh(changeset));
  }

  private refresh(change: WorkPackageChangeset) {
    // Prepare the fields that are required always
    this.wpResource = change.projectedResource;
    const schema = this.schema(this.wpResource);
    if (!this.wpResource.project) {
      this.projectContext = { matches: false, href: null, id: null };
    } else {
      const project = this.wpResource.project as unknown & { href: string; id: string };
      const workPackageId = this.workPackage.id;
      if (!workPackageId) {
        throw new Error('work package id is invalid');
      }

      this.projectContext = {
        id: project.id,
        href: this.PathHelper.projectWorkPackagePath(project.id, workPackageId),
        matches: project.href === this.currentProject.apiv3Path,
      };
    }

    if (isNewResource(this.wpResource)) {
      this.updateWorkPackageCreationState(change);
    }

    // eslint-disable-next-line no-underscore-dangle
    this.groupedFields = this.rebuildGroupedFields(change, schema._attributeGroups) as GroupDescriptor[];
    if (!isNewResource(this.wpResource)) {
      this.defineIsFileNeeded();
    }
    console.log('Refresh', schema, this.wpResource);
    this.cdRef.detectChanges();
  }

  private blockField(schema: SchemaResource, wpResource: WorkPackageResource, status?: string) {
    this.typeName = this.wpResource.$source._links.type.title;
    this.statusName = status != undefined ? status : this.wpResource.$source._links.status.title;

    const notCloseOrPause = this.statusName != this.close || this.statusName != this.pause; 
    const notWorkCloseOrPause = this.statusName != this.workClose1 || this.statusName != this.workClose2 || this.statusName != this.pause; 
    const isCloseOrPause = this.statusName == this.close || this.statusName == this.pause; 

    console.log('BlockField', this.typeName, this.statusName, wpResource,
       "notCloseOrPause",notCloseOrPause,
       "isCloseOrPause", isCloseOrPause,
       "notWorkCloseOrPause",notWorkCloseOrPause);

    // 1а Этап                         
    if (this.typeName == this.stage && notCloseOrPause) {
      console.log('Этап notCloseOrPause');
      this.stageBlockField(schema);
      return;
    }

    // 2 КП
    if (this.typeName == this.kp && isCloseOrPause) {
      console.log('КП isCloseOrPause');
      this.kpBlockField(schema, false); // false -> allBlockField 2
      return;
    }
    
    // 1 КП                         
    if (this.typeName == this.kp && notCloseOrPause) {
      console.log('КП notCloseOrPause');
      this.kpBlockField(schema, true); // true -> kpBlockField 1 
      return;
    }

    // 2 Работа
    if (this.typeName == this.work && (this.statusName == this.workClose1 || this.statusName == this.workClose2)) {
      console.log('Работа isClose');
      this.workBlockField(schema, 'close');
      return;
    }

    // 2 Работа
    if (this.typeName == this.work && this.statusName == this.workPause) {
      console.log('Работа isPause');
      this.workBlockField(schema, 'pause');
      return;
    }

        // 2 Работа                     
    if (this.typeName == this.work && notWorkCloseOrPause) {
      console.log('Работа notWorkCloseOrPause');
      this.workBlockField(schema, 'or'); // true -> workBlockField 1
      return;
    }
  }

  private unBlockField(schema: SchemaResource) {
    console.log("unBlockField", schema);
    Object.entries(schema).forEach(([key, item]) => {
      console.log('key item', key, item);
      if (item.$source != undefined) {
        item.$source.writable = true;
        console.log('unBlock', key, item.$source.name, "writable", item.$source.writable);
      }
      else if (item.writable != undefined) {
        item.writable = true;
        console.log('unBlock else', key, item.name, "writable", item.writable);
      }
    });
  }
  
  // Этап
  private stageBlockField(schema: SchemaResource) {
    Object.entries(schema).forEach(([key, item]) => {
      if (item.$source) {
        item.$source.writable = false;
        console.log('Этап', key, item.$source.name, "writable", item.$source.writable);
      }
      else if (item.writable != undefined) {
        item.writable = false;
        console.log('Этап Else', key, item.name, "writable", item.writable);
      }
    });
  }

  // КП
  private kpBlockField(schema: SchemaResource, flag: boolean) {
    Object.entries(schema).forEach(([key, item]) => {

      if (item.$source) {
        item.$source.writable = flag ? !(kpBlockField.includes(item.$source.name)) : !(allBlockField.includes(item.$source.name))
        console.log('КП', key, item.$source.name, "writable", item.$source.writable);
      }
      else if (item.writable != undefined) {
        item.writable = flag ? !(kpBlockField.includes(item.name)) : !(allBlockField.includes(item.name));
        console.log('КП Else', key, item.name, "writable", item.writable);
      }
    });
  }

  // Работа
  private workBlockField(schema: SchemaResource, type: string) {
    Object.entries(schema).forEach(([key, item]) => {
      if (item.$source) {
        item.$source.writable = this.predicateWork(type, item.$source.name);
        console.log('Работа', key, item.$source.name, "writable", item.$source.writable);
      }
      else if (item.writable != undefined) {
        item.writable = this.predicateWork(type, item.name);
        console.log('Работа Else', key, item.name, "writable", item.writable);
      }
    });
  }

  private predicateWork(type: string, value: string): boolean {
    switch (type) {
      case 'or':
        return !workBlockField.includes(value);
      case 'close':
        return !workCloseBlockField.includes(value);
      case 'pause':
        return !workPauseBlockField.includes(value);
      default:
        return true;
    }
  }

  defineIsFileNeeded() {
    this.getCustomFields().forEach(([key, keySchema]) => {
      if (keySchema?.options?.identifier && keySchema?.options?.identifier === 'triebuietsia-fail') {
        if (this.workPackage?.[key] !== undefined) {
          this.isFileNeeded = this.workPackage[key];
        }
      }
    });
  }

  public isCustomFieldFilled(fieldId: string) {
    let isFilled = false;
    this.getCustomFields().forEach(([key, keySchema]) => {
      if (keySchema?.options?.identifier && keySchema?.options?.identifier === fieldId) {
        if (this.workPackage?.[key] && this.workPackage?.[key] !== '' && this.workPackage?.[key] !== 0) {
          isFilled = true;
        }
      }
    });
    return isFilled;
  }
  public getCustomFields(fieldId: string): [string, any] | undefined;
  public getCustomFields(): [string, any][];

  public getCustomFields(fieldId?: string): unknown {
    if (fieldId != undefined) {
      let customField: [string, any] | undefined = undefined;
      Object.entries(this.schema(this.wpResource)).forEach((field) => {
        if (/customField\d+/.exec(field[0])) {
          if (field[0] === fieldId) {
            customField = field;
          }
        }
      });
      return customField;
    }

    const customFields: [string, any][] = [];
    Object.entries(this.schema(this.wpResource)).forEach((field) => {
      if (/customField\d+/.exec(field[0])) {
        customFields.push(field);
      }
    });

    return customFields;
  }

  private updateWorkPackageCreationState(change: WorkPackageChangeset) {
    const resource = change.projectedResource;
    if (!this.currentProject.inProjectContext) {
      this.projectContext.field = this.getFields(change, ['project']);
      this.workPackage.project = resource.project as HalResource;
    }

    if (resource.project === null) {
      this.projectStorages.next([]);
    } else {
      const project = resource.project as unknown & { href: string; id: string };
      combineLatest([
        this.projectsResourceService.requireEntity(project.href),
        this.projectStoragesService.requireCollection({ filters: [['projectId', '=', [project.id]]] }),
        this.currentUserService.hasCapabilities$('file_links/manage', project.id),
      ])
        .pipe(
          map(([p, projectStorages, manageFileLinks]) => {
            if (!p._links.storages || !manageFileLinks) {
              return [];
            }

            return projectStorages;
          }),
          first()
        )
        .subscribe((ps) => {
          this.projectStorages.next(ps);
        });
    }
  }

  /**
   * Returns whether a group should be hidden due to being empty
   * (e.g., consists only of CFs and none of them are active in this project.
   */
  public shouldHideGroup(group: GroupDescriptor): boolean {
    // Hide if the group is empty
    const isEmpty = group.members.length === 0;

    // Is a query in a new screen
    const queryInNew = isNewResource(this.workPackage) && !!group.query;

    return isEmpty || queryInNew;
  }

  /**
   * angular 2 doesn't support track by property any more but requires a custom function
   * https://github.com/angular/angular/issues/12969
   * @param _index
   * @param elem
   */
  public trackByName(_index: number, elem: { name: string }): string {
    return elem.name;
  }

  /**
   * Allow other modules to register groups to insert into the single view
   */
  public prependedAttributeGroupComponents() {
    return this.hook.call('prependedAttributeGroups', this.workPackage);
  }

  public attributeGroupComponent(group: GroupDescriptor) {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    return this.hook.call('attributeGroupComponent', group, this.workPackage).pop() || null;
  }

  public attachmentListComponent() {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    return this.hook.call('workPackageAttachmentListComponent', this.workPackage).pop() || null;
  }

  public attachmentUploadComponent() {
    // we take the last registered group component which means that
    // plugins will have their say if they register for it.
    return this.hook.call('workPackageAttachmentUploadComponent', this.workPackage).pop() || null;
  }

  /*
   * Returns the work package label
   */
  public get idLabel(): string {
    return `#${this.workPackage.id || ''}`;
  }

  public showSwitchToProjectBanner(): boolean {
    return !this.isNewResource && this.projectContext && !this.projectContext.matches;
  }

  public get switchToProjectText(): string {
    const id = idFromLink(this.workPackage.project.href);
    const projectPath = this.PathHelper.projectPath(id);
    const projectName = this.workPackage.project.name as string;
    const project = `<a href='${projectPath}' class='project-context--switch-link'>${projectName}<a>`;
    return this.I18n.t('js.project.click_to_switch_to_project', { projectname: project });
  }

  showTwoColumnLayout(): boolean {
    return this.$element[0].getBoundingClientRect().width > 750;
  }

  private rebuildGroupedFields(change: WorkPackageChangeset, attributeGroups: any) {
    if (!attributeGroups) {
      return [];
    }

    return attributeGroups.map((group: any) => {
      const groupId = this.getAttributesGroupId(group);

      if (group._type === 'WorkPackageFormAttributeGroup') {
        return {
          name: group.name,
          id: groupId || randomString(16),
          members: this.getFields(change, group.attributes),
          type: group._type,
          isolated: false,
        };
      }
      return {
        name: group.name,
        id: groupId || randomString(16),
        query: this.halResourceService.createHalResourceOfClass(QueryResource, group._embedded.query),
        relationType: group.relationType,
        members: [group._embedded.query],
        type: group._type,
        isolated: true,
      };
    });
  }

  /**
   * Maps the grouped fields into their display fields.
   * May return multiple fields (for the date virtual field).
   */
  private getFields(change: WorkPackageChangeset, fieldNames: string[]): FieldDescriptor[] {
    const descriptors: FieldDescriptor[] = [];
    const customDateGroup: TGroupAttributes<keyof AvailableCustomDateSlugsType> | any = {};
    const identifierMap = new Map<string, {group: string, attribute: string}>();

    Object.entries(AvailableCustomDateSlugs).forEach(([group, attributes]) => {
      Object.entries(attributes).forEach(([attribute, identifier]) => {
        identifierMap.set(identifier, {group, attribute});
      });
    });

    fieldNames.forEach((fieldName: string) => {
      if (fieldName.startsWith('customField')) {
        const field = this.getCustomFields(fieldName);
        const customKey = field?.[0];
        const customFieldSchema = field?.[1] as IFieldSchema;

        if (!customFieldSchema?.options?.identifier) {
          return;
        }

        const mappedValue = identifierMap.get(customFieldSchema.options.identifier);
        if (mappedValue) {
          const [group, attribute] = Object.values(mappedValue);
          if(group && attribute){
            if(!customDateGroup[group]){
              customDateGroup[group] = {};
            }
            customDateGroup[group][attribute] = {
              key: customKey,
              label: customFieldSchema.name,
              schema: customFieldSchema,
            };
          }
          return;
        }
      }
      if (fieldName === 'date') {
        descriptors.push(this.getDateField(change));
        return;
      }
      if (fieldName === 'factDate') {
        descriptors.push(this.getFactDateField(change));
        return;
      }

      if (!change.schema.ofProperty(fieldName)) {
        console.log('Unknown field for current schema', fieldName);
        return;
      }

      const field: DisplayField = this.displayField(change, fieldName);

      descriptors.push({
        name: fieldName,
        label: field.label,
        multiple: false,
        spanAll: field.isFormattable,
        field,
      });
    });

    const customKeys: string[] = [];
    Object.keys(customDateGroup).forEach((key) => {
      const group = customDateGroup[key];
      if(!group){
        return;
      }
      descriptors.push(...this.getCustomMultiDateField(change, group));
      this.displayFieldService.setCustomFieldOption(group.start.key,group);
      customKeys.push(group.start.key);
    });

    this.editFieldService.addSpecificFieldType(
      'WorkPackage',
      CombinedCustomDateEditFieldComponent,
      'combinedCustomDate',
      customKeys
    );
    
    return descriptors;
  }

  /**
   * We need to discern between milestones, which have a single
   * 'date' field vs. all other types which should display a
   * combined 'start' and 'due' date field.
   */
  private getDateField(change: WorkPackageChangeset): FieldDescriptor {
    const object: FieldDescriptor = {
      name: '',
      label: this.I18n.t('js.work_packages.properties.date'),
      spanAll: false,
      multiple: false,
    };

    if (change.schema.ofProperty('date')) {
      object.field = this.displayField(change, 'date');
      object.name = 'date';
    } else {
      object.field = this.displayField(change, 'combinedDate');
      object.name = 'combinedDate';
    }
    return object;
  }

  private getCustomMultiDateField(change: WorkPackageChangeset, fields: TGroupAttributes<keyof AvailableCustomDateSlugsType>): FieldDescriptor[] {
    const options: any = {
      type: 'combinedCustomDate',
      duration: { key: fields.duration?.key, label: fields.duration?.label },
    };

    const descriptors: FieldDescriptor[] = [];

    if(fields.start){
      options.start = { key: fields.start?.key, label: fields.start?.label };
      const startObject: FieldDescriptor = {
        name: options.start.key,
        label: options.start.label,
        spanAll: false,
        multiple: false,
        options,
      };
      startObject.field = this.customDisplayDateField(change, options.start.key, fields.start?.schema, options);
      descriptors.push(startObject);
    }

    if(fields.end){
      options.end = { key: fields.end?.key, label: fields.end?.label };
      const endObject: FieldDescriptor = {
        name: options.end.key,
        label: options.end.label,
        spanAll: false,
        multiple: true,
      }
      endObject.field = this.displayFieldService.getField(change.projectedResource, options.end.key,fields.end?.schema, {
        container: 'single-view',
        injector: this.injector,
        options: {},
      });
      descriptors.push(endObject);
    }

   if(fields.duration){
    const durationObject: FieldDescriptor = {
      name: options.duration.key,
      label: options.duration.label,
      spanAll: false,
      multiple: true,
    }
    durationObject.field = this.displayFieldService.getField(change.projectedResource, options.duration.key, fields.duration?.schema, {
      container: 'single-view',
      injector: this.injector,
      options: {},
    });
    descriptors.push(durationObject);
   }

    return descriptors;
  }

  private getFactDateField(change: WorkPackageChangeset): FieldDescriptor {
    const object: FieldDescriptor = {
      name: '',
      label: this.I18n.t('js.work_packages.properties.factDate'),
      spanAll: false,
      multiple: false,
    };

    if (change.schema.ofProperty('factDate')) {
      object.field = this.displayField(change, 'factDate');
      object.name = 'factDate';
    } else {
      object.field = this.displayField(change, 'combinedFactDate');
      object.name = 'combinedFactDate';
    }
    return object;
  }

  /**
   * Get the current resource context change from the WP resource.
   * Used to identify changes in the schema or project that may result in visual changes
   * to the single view.
   *
   * @param {WorkPackage} workPackage
   * @returns {ResourceContextChange}
   */
  private contextFrom(workPackage: WorkPackageResource): ResourceContextChange {
    const schema = this.schema(workPackage);
    const status = workPackage.$source._links.status.title as string
    console.log("ContextFrom", schema, status, status != this.backlog);
    //status != this.backlog ? this.blockField(schema, workPackage, status) : this.unBlockField(schema);

    let schemaHref: string | null;
    const projectHref: string | null = workPackage.project && workPackage.project.href;

    if (schema.baseSchema) {
      schemaHref = schema.baseSchema.href;
    } else {
      schemaHref = schema.href;
    }
    return {
      isNew: workPackage.isNew,
      schema: schemaHref,
      project: projectHref,
    };
  }

  private displayField(change: WorkPackageChangeset, name: string): DisplayField {
    return this.displayFieldService.getField(change.projectedResource, name, change.schema.ofProperty(name), {
      container: 'single-view',
      injector: this.injector,
      options: {},
    });
  }

  private customDisplayDateField(
    change: WorkPackageChangeset,
    name: string,
    schema: IFieldSchema,
    fieldContext: { [key: string]: any }
  ): DisplayField {
    return this.displayFieldService.getField(change.projectedResource, name, schema, {
      container: 'single-view',
      injector: this.injector,
      options: fieldContext,
    });
  }

  private getAttributesGroupId(group: any): string {
    const overflowingIdentifier = this.$element
      .find(`[data-group-name=\'${group.name}\']`)
      .data(overflowingContainerAttribute);

    if (overflowingIdentifier) {
      return overflowingIdentifier.replace('.__overflowing_', '');
    }
    return '';
  }

  private schema(resource: WorkPackageResource) {
    if (this.halEditing.typedState(resource).hasValue()) {
      return this.halEditing.typedState(this.workPackage).value!.schema;
    }
    return this.schemaCache.of(resource);
  }
}
